import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const ErrorPage = (props: { error?: Error }) => {
  const { t } = useTranslation();
  if (!props.error) {
    return null;
  }

  return (
    <div>
      <Typography variant="h4">{t('error.title')}</Typography>
    </div>
  );
};
export default ErrorPage;
